<script>
import { useStore } from 'vuex'
import useProgram from '@/composables/program'
import { onMounted, ref } from 'vue'
import FormBotoes from '@/components/forms_bottom_buttons/index.vue'
import CustomInput from '@/components/custom_input/index.vue'
import CKEditor from "@ckeditor/ckeditor5-vue"
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import uploadFile from '@/components/file/upload-file.vue'
import { sendFunctionRequest } from '../../../../helpers/dynamicTable/sendFunctionRequest'
import Swal from 'sweetalert2'
import { useRoute } from 'vue-router'
import router from '@/router'
import { getValueListById } from '../../../../helpers/forms/getListaValorById'
import AzureHelper from '../../../../helpers/azure'

export default {
    props: {
        program_schema: {
            type: Object,
            required: true,
        },
        dados: {
            type: Object,
            required: true,
        },
        registro_id: {
            type: String,
            required: false,
        },
    },
    setup(props) {
        const store = useStore()
        const browser_route = useRoute()
        const formType = store.state.programForm.formType
        const showUploadFileModal = ref(false)
        const files = ref([])
        const attachedFiles = ref([])
        const formRef = ref(null);
        const programa = {
            program_schema: props.program_schema,
            dados: props.dados,
            registro_id: props.registro_id
        }
        const function_add_document = ref(programa.program_schema.sis_funcionalidade.find(sf => sf.id_funcionalidade == 'dd34d8e4-d993-4e6b-8c99-a411a959550a'))
        const tipoChamadoOptionsList = ref([])

        const {
            setProgramForm,
            addNewDetailTableForm,
            removeDetailTableForm,
            handleFormSubmit,
            sis_detail_table,
            baseFormModifier,
            loading,
            btnloading
        } = useProgram(formType, programa)

        onMounted(() => {
            setProgramForm()
        })

        const editor = ClassicEditor
        const editorData = ''
        const editorConfig = {
            toolbar: [
                'heading',
                '|',
                'bold',
                'italic',
                'link',
                'bulletedList',
                'numberedList',
                '|',
                'undo',
                'redo'
            ],
            translations: [ ]
        }
        const handleEditorDataChange = (value) => {
            baseFormModifier.value.setMutatedValues('descricao', value)
        }
        const setShowUploadFileModal = () => {
            files.value = []
            showUploadFileModal.value = true
        }
        const attachFiles = () => {
            attachedFiles.value.push(...files.value)
            showUploadFileModal.value = false
            files.value = []
        }
        
        async function saveFiles(register){
            const documentsToUpload = attachedFiles.value.map(file => {
                return {
                    nome_documento: file.name,
                    arquivo_base64: null,
                    tamanho: file.size,
                    descricao: file.descricao,
                    id_empresa: register.id_empresa,
                }
            });

            const primary_key_name = programa.program_schema.sis_tabela.sis_campo_tabela.find((column) => column.chave_primaria).nome
            const body = {
                id: register[primary_key_name] + '',
                id_programa: browser_route.params.targetId,
                condicao_adicional: "",
                lista_id_empresa: JSON.parse(localStorage.getItem('companiesGlobalFilter')) ?? [],
                data_trabalho: JSON.parse(localStorage.getItem('dataTrabalho')),
                navegador: false,
                ordenacao: null,
                quantidade_registros: 100,
                pagina: 0,
                dados: {
                        sis_documento: {
                        registros: [...documentsToUpload],
                    },
                    [programa.program_schema.sis_tabela.nome_tabela]: {
                        registros: [
                            {
                                [primary_key_name]: register[primary_key_name]
                            },
                        ],
                    },
                },
                rota: programa.program_schema.rota_backend + function_add_document.value.rota_backend,
            }

            await sendFunctionRequest(body, 
                async (res) => {
                    Swal.fire({
                        icon: "success",
                        text: "Salvo com sucesso!",
                        confirmButtonColor: "#34c38f",
                        confirmButtonText: "OK",
                    });
                    const query = Object.assign({}, browser_route.query);
                    query.registro_id =  register[primary_key_name];
                    query.tipo = 'edicao';
                    await router.push({ query });
                    store.commit('programForm/setFormType', 'edition');

                    const documents = res.data.retorno.dados.sis_documento.registros
                    const documentsToUploadAzure = []
                    attachedFiles.value.forEach(file => {
                        const filteredDocuments = documents
                            .filter(doc => doc.nome_documento == file.name && doc.tamanho == file.size)
                            .sort((a, b) => new Date(b.data_inclusao) - new Date(a.data_inclusao))
                        const document = filteredDocuments[0] ?? null
                        if (document) {
                            document.file = file
                            documentsToUploadAzure.push(document)
                        }
                    })

                    const azurehelper = new AzureHelper()
                    await azurehelper.uploadDocuments(documentsToUploadAzure)
                },
                async (error) => {
                    Swal.fire({
                        icon: "error",
                        text: error,
                        confirmButtonColor: "#34c38f",  
                        confirmButtonText: "OK",
                    });
                    const query = Object.assign({}, browser_route.query);
                    query.registro_id =  register[primary_key_name];
                    query.tipo = 'edicao';
                    await router.push({ query });
                    store.commit('programForm/setFormType', 'edition');
                }
            )
        }
        const save = async () => {
            btnloading.value = true;
            let res = null
            res = await handleFormSubmit({ target: formRef.value }, null, null, false, true)
            if(res){
                if(!res.status || res.error){
                    Swal.fire({
                        icon: "error",
                        text: res.mensagem ? res.mensagem : res.erro,
                        confirmButtonColor: "#34c38f",  
                        confirmButtonText: "OK",
                    });
                }
                if(res.status && attachedFiles.value.length){
                    const { dados } = res.retorno
                    const register = dados[programa.program_schema.sis_tabela.nome_tabela].registros[0]
                    await saveFiles(register)
                }else if(res.status){
                    const { dados } = res.retorno
                    const register = dados[programa.program_schema.sis_tabela.nome_tabela].registros[0]
                    const primary_key_name = programa.program_schema.sis_tabela.sis_campo_tabela.find((column) => column.chave_primaria).nome
                    const query = Object.assign({}, browser_route.query);
                    query.registro_id =  register[primary_key_name];
                    query.tipo = 'edicao';
                    await router.push({ query });
                    store.commit('programForm/setFormType', 'edition');
                }
            }
            btnloading.value = false;
        }
        const generateURL = (file) => {
            if (file.type != 'application/pdf') {
                let fileSrc = URL.createObjectURL(file)
                setTimeout(() => {
                    URL.revokeObjectURL(fileSrc)
                }, 1000)
                return fileSrc
            }
            else {
                let fileSrc = new String(URL.createObjectURL(file))
                setTimeout(() => {
                    URL.revokeObjectURL(fileSrc)
                }, 1000)
                return fileSrc
            }
        }

        const setTipoChamadoOptionsList = () => {
            if(!baseFormModifier.value.mutatedValues.id_departamento || isNaN(baseFormModifier.value.mutatedValues.id_departamento)){
                baseFormModifier.value.setMutatedValues('tipo', null)
                tipoChamadoOptionsList.value = []
                return
            }
            getValueListById('12df672c-6fdc-44e3-881a-9ebea7a030d6', 'id_departamento = ' + baseFormModifier.value.mutatedValues.id_departamento)
            .then(res => {
                baseFormModifier.value.setMutatedValues('tipo', null)
                const { sis_lista_valor: { sis_opcao_lista_valor } } = res
                tipoChamadoOptionsList.value = sis_opcao_lista_valor ?? []
            })
            .catch((error) => {
                Swal.fire({
                    icon: "error",
                    text: error,
                    confirmButtonColor: "#34c38f",
                    confirmButtonText: "OK",
                });
                console.log(error)
            })
        }

        return {
            generateURL,
            editor,
            editorData,
            editorConfig,
            formType,
            loading,
            btnloading,
            handleFormSubmit,
            baseFormModifier,
            sis_detail_table,
            addNewDetailTableForm,
            removeDetailTableForm,
            handleEditorDataChange,
            setShowUploadFileModal,
            showUploadFileModal,
            attachFiles,
            attachedFiles,
            files,
            save,
            formRef,
            setTipoChamadoOptionsList,
            tipoChamadoOptionsList,
        }
    },
    components: {
        FormBotoes,
        CustomInput,
        CKEditor: CKEditor.component,
        uploadFile
    }
}
</script>

<template>
    <div>
        <template v-if="loading">
            <div class="text-center">
                <b-spinner variant="primary"></b-spinner>
            </div>
        </template>
        <form v-else @submit.prevent="save" class="needs-validation" novalidate ref="formRef">
            <div  class="row pb-4 mb-4">
                <div class="col-xxl-9">
                    <div class="card mb-0 mt-4">
                        <div class="card-header d-flex align-items-center justify-content-between" style="height:70px;">
                            <div class="d-flex align-items-center">
                                <h6 class="card-title fw-semibold mb-0 me-2">{{ program_schema.sis_tabela.denominacao }}</h6>
                            </div>
                        </div>

                        <div class="card-body" style="min-height:482px;">
                            <div class="row">
                                <div class="col-2 d-none d-lg-block"></div>
                                <div class="col-12 col-md-6 col-lg-4">
                                    <div class="mb-3">
                                        <CustomInput :formState="baseFormModifier" :formType="formType"
                                            :columnSchema="baseFormModifier.getColumnByName('id_empresa')"
                                            :showLabel="true" />
                                    </div>
                                </div>
                                <div class="col-12 col-md-6 col-lg-4">
                                    <div class="mb-3">
                                        <CustomInput :formState="baseFormModifier" :formType="formType"
                                            :columnSchema="baseFormModifier.getColumnByName('id_departamento')"
                                            :showLabel="true"
                                            :onValueChange="setTipoChamadoOptionsList"
                                            />
                                    </div>
                                </div>
                                <div class="col-2 d-none d-lg-block"></div>
                            </div>
                            <div class="row">
                                <div class="col-2 d-none d-lg-block"></div>
                                <div class="col-12 col-md-6 col-lg-4">
                                    <div class="mb-3">
                                        <CustomInput :formState="baseFormModifier" :formType="formType"
                                            :columnSchema="baseFormModifier.getColumnByName('tipo')"
                                            :showLabel="true"
                                            :customOptionsList="tipoChamadoOptionsList"
                                        />
                                    </div>
                                </div>
                                <div class="col-12 col-md-6 col-lg-4">
                                    <div class="mb-3">
                                        <CustomInput :formState="baseFormModifier" :formType="formType"
                                            :columnSchema="baseFormModifier.getColumnByName('titulo')"
                                            :showLabel="true" />
                                    </div>
                                </div>
                                <div class="col-2 d-none d-lg-block"></div>
                            </div>
                            <div class="row">
                                <div class="col-2 d-none d-lg-block"></div>
                                <div class="col-12 col-md-6 col-lg-4">
                                    <div class="mb-3">
                                        <CustomInput :formState="baseFormModifier" :formType="formType"
                                            :columnSchema="baseFormModifier.getColumnByName('data_vencimento')"
                                            :showLabel="true" />
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-2 d-none d-lg-block"></div>
                                <div class="col-12 col-lg-8">
                                    <label>Descrição</label>
                                    <div id="ckeditor-chamado">
                                        <CKEditor :editor="editor" v-model="editorData" :config="editorConfig" @input="handleEditorDataChange"/>
                                    </div>
                                </div>
                                <div class="col-2 d-none d-lg-block"></div>
                            </div>
                        </div>

                    </div>
                </div>
                <div class="col-xxl-3">
                    <div class="card mt-4 mb-2">
                        <div class="card-header d-flex align-items-center justify-content-between">
                            <div class="d-flex align-items-center">
                                <h6 class="card-title fw-semibold mb-0 me-2">Anexos</h6>
                                <button type="button" @click="setShowUploadFileModal" class="btn btn-ghost-secondary btn-icon btn-sm fs-18" v-b-tooltip.hover.top="'Anexar documentos'"><i class="mdi link-success mdi-file-plus align-bottom"></i></button>
                            </div>
                            <span class="small text-muted">{{attachedFiles.length}} Anexo(s)</span>
                        </div>
                        <div class="card-body">
                            <div data-simplebar style="height: 450px;" class="px-3 mx-n3">
                                <div v-for="file, index in attachedFiles" :key="index" :class="['preview-card border rounded mb-2 position-relative form-control', index === 0 ? 'mt-4' : '']">
                                    <div class="d-flex flex-column align-items-center flex-wrap  p-2 justify-content-center text-center">
                                        <div class="border-dashed border p-2 mx-1 text-center" v-if="(/\.(|doc|docx|xlsx|avi)$/).test(file.name)">
                                            <p>Visualização indisponível</p>
                                        </div>
                                        <iframe v-else-if="file.type === 'application/pdf'" class="img-thumbnail me-2" data-holder-rendered="true" frameBorder="0" scrolling="no"
                                            alt="200x200" width="200" :src="generateURL(file)" />
                                        <b-img v-else-if="['image/jpeg', 'image/jpg', 'image/png'].includes(file.type)" class="img-thumbnail me-2" alt="200x200" width="200"
                                        :src="generateURL(file)" data-holder-rendered="true" />
                                        <img v-else-if="['video/mp4', 'video/mov'].includes(file.type)" class="img-thumbnail me-2" alt="100x200" height="100px" width="200" src="@/assets/images/video_file_type.svg" data-holder-rendered="true" />
                                    
                                        <div class="flex-grow-1" style="min-width:max-content;">
                                            <div class="pt-1">
                                                <h5 class="fs-11 mb-1" data-dz-name="">
                                                    {{ file.name }}
                                                </h5>
                                                <p v-if="file.size" class="fs-9 text-muted mb-0" data-dz-size="">
                                                    <strong>{{ ((file.size / 1024) / 1000).toFixed(2) }}</strong> MB
                                                </p>
                                            </div>
                                        </div>
                                        <div class="flex-shrink-0 mt-2 ms-lg-3 position-absolute" style="top:-15px;right:-10px;">
                                            <b-button-group>
                                                <button type="button"
                                                    @click="attachedFiles.splice(index, 1)"
                                                    class="bg-white btn btn-white border rounded-circle btn-icon btn-sm fs-20"
                                                    v-b-tooltip.hover.top="'Remover Anexo'">
                                                    <i class="link-danger ri-delete-bin-5-fill align-bottom"></i>
                                                </button>
                                            </b-button-group>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <FormBotoes :formType="formType" :loading="btnloading" />
            </div>
        </form>
        <b-modal id="chamado-criacao-upload" title="Adicione Arquivos" fade scrollable centered size="lg" hide-footer
            v-model="showUploadFileModal">
            <uploadFile :updateFile="(data) => (files = data)" />
            <div class="d-grid gap-2">
                <b-button type="button" class="btn btn-sm btn-success" @click="attachFiles">
                    Anexar
                </b-button>
            </div>
        </b-modal>
    </div>
</template>