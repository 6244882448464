<script>

import { computed, ref, watch } from 'vue';

export default {
    props: {
        original_documents: {
            type: Array,
            required: true
        },
        showFileModal: {
            type: Boolean,
            required: true
        },
        setShowFileModal: {
            type: Function,
            required: true
        },
        files: {
            type: Array,
            required: true
        },
        onClose: {
            type: Function,
            required: true
        },
        title: {
            type: String,
            required: false,
            default: 'Documentos'
        },
        showZoomDescription: {
            type: Boolean,
            required: false,
            default: true
        }
    },
    setup(props) {
        const show = ref(false)
        const currentIndex = ref(0)

        watch(() => props.showFileModal, (value) => {
            show.value = value
            if (value) {
                currentIndex.value = 0
                document.addEventListener('keyup', onKeyUp)
            }
        })
        watch(() => show.value, () => {
            if (!show.value) {
                props.setShowFileModal(false)
                props.onClose()
                currentIndex.value = 0
            }
        })

        function prevSlide() {
            document.querySelectorAll('.video_player').forEach(vp => {
                vp.pause()
                vp.currentTime = 0
            })
            if (currentIndex.value > 0) {
                currentIndex.value--
            } else if (currentIndex.value == 0) {
                currentIndex.value = props.files.length - 1
            }
        }

        function nextSlide() {
            document.querySelectorAll('.video_player').forEach(vp => {
                vp.pause()
                vp.currentTime = 0
            })
            if (currentIndex.value < props.files.length - 1) {
                currentIndex.value++
            } else if (currentIndex.value == props.files.length - 1) {
                currentIndex.value = 0
            }
        }

        const getCurrentDocumentData = computed(() => {
            return props.original_documents.find(od => od.id_documento == props.files[currentIndex.value].id_documento)
        })
        function onKeyUp(event) {
            if (event.key === 'ArrowRight') {
                nextSlide()
            }
            if (event.key === 'ArrowLeft') {
                prevSlide()
            }
            if(event.key === 'Escape'){
                show.value = false
            }
        }
        return {
            show,
            currentIndex,
            prevSlide,
            nextSlide,
            getCurrentDocumentData,
            onKeyUp
        }
    },
    computed: {
        pdfRegex() {
            return /\.pdf$/i
        }
    },
}
</script>

<template>
    <b-modal id="documentsVisualizationModal" fade scrollable centered size="xl" hide-header hide-footer v-model="show">
        <div class="modal-content" style="
          width: 95vw;
          height: 95vh;
          position: fixed;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        ">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalScrollableTitle">
                    {{ this.$props.title }}
                </h5>
            </div>
            <div class="modal-body">
                <div class="row">
                    <div class="col-12">
                        <div id="carouselExampleControlsNoTouching" class="carousel slide" data-bs-touch="false"
                            data-bs-interval="false">
                            <div class="carousel-inner" role="listbox">
                                <div v-for="(file, index) in this.$props.files" 
                                    :key="file.id_documento"
                                    :class="['carousel-item', index == currentIndex ? ' active' : '']"
                                >
                                    <template v-if="!getCurrentDocumentData?.link_upload ||  getCurrentDocumentData.link_upload && file.tamanho_arquivo == getCurrentDocumentData.tamanho">
                                        <div class="border-dashed border p-2 mx-1 text-center" v-if="(/\.(|doc|docx|xlsx|avi)$/).test(file.name)">
                                            <p>Visualização indisponível</p>
                                        </div>
                                        <iframe v-else-if="pdfRegex.test(file.nome_documento)"
                                            class="d-block img-fluid mx-auto" style="height: 65vh; width: 100vw"
                                            :src="file.link_azure + '#view=fitH'" />
                                        <div v-else-if="['ogg', 'mp4'].includes(file.nome_documento.split('.').at(-1))"
                                            class="d-flex justify-content-center align-items-center">
                                            <video class="video_player mx-auto" style="height: 65vh; width: 100%"
                                                controls>
                                                <source :src="file.link_azure"
                                                    :type="'video/' + file.nome_documento.split('.').at(-1)">
                                                Your browser does not support the video tag.
                                            </video>
                                        </div>
                                        <img v-else class="d-block img-fluid mx-auto" style="height: 65vh; width: auto" :src="file.link_azure" />
                                        <p class="text-center mt-2">{{ file.nome_documento }}</p>
                                    </template>
                                    <template v-else>
                                        <div class="d-flex justify-content-center align-items-center" style="height:60vh;">
                                            <p class="text-center mt-2 text-primary">
                                                <strong>{{  getCurrentDocumentData.usuario_inclusao }}</strong> ainda não finalizou o upload deste arquivo.
                                            </p>
                                        </div>
                                        <p class="text-center mt-2">
                                            {{ file.nome_documento }}
                                        </p>
                                    </template>
                                </div>
                                <a @click="prevSlide"
                                    class="carousel-control-prev slider ri-arrow-left-s-line text-white"
                                    href="#carouselExampleControlsNoTouching"
                                    role="button"
                                    data-bs-slide="prev"
                                    v-b-tooltip.hover.top="'<- (seta esquerda)'">
                                </a>
                                <a @click="nextSlide"
                                    class="carousel-control-next slider ri-arrow-right-s-line text-white"
                                    href="#carouselExampleControlsNoTouching"
                                    role="button"
                                    data-bs-slide="next"
                                    v-b-tooltip.hover.top="'-> (seta direita)'">
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <p class="text-center" v-if="showZoomDescription">
                * Pressione a tecla Control (Ctrl) e gire o Scroll (rodinha) do mouse
                para aumentar/diminuir o zoom no documento.
            </p>
            <div class="modal-footer border-top">
                <button @click="show = !show" class="btn btn-primary me-auto" v-b-tooltip.hover.top="'ESC'">Sair</button>
            </div>
        </div>
    </b-modal>
</template>

<style scoped>
.slider {
  top: 50%;
  transform: translateY(-50%);
  background-color: #000;
  border-radius: 50%;
  height: 35px;
  width: 35px;
  position: fixed;
}

iframe {
  zoom: 1;
}
</style>