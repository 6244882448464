<script>
import Swal from 'sweetalert2';
import { useStore } from 'vuex';
import { sendFunctionRequest } from '../../helpers/dynamicTable/sendFunctionRequest';
import { downloadDocument } from '../../helpers/donwloadDocument';
import { makeResizable } from '../../helpers/makeResizable';
import { Grid } from 'gridjs';
import ptBR from '../../helpers/dynamicTable/ptBrLanguage';
import { useRoute } from 'vue-router';

import { inject, reactive, ref } from 'vue';
import ConfiguracaoConsulta from '@/components/configuracao_consulta/index';
import VisualizarRespostasCollapse from '@/components/visualizar_respostas/collapse';
import uploadFile from "@/components/file/upload-file.vue";
import FunctionButtons from './functionButtons.vue';
import { getValueListById } from '@/helpers/forms/getListaValorById';
import useValueListTable from '@/helpers/dynamicTable/tabelaListaValor';
import { vue3Debounce } from 'vue-debounce'
import { v4 as uuidv4 } from 'uuid';
import { getListValueIdByFunctionalityId } from '../../helpers/static_get_value_list_id_by_function_id';
import AzureHelper from '../../helpers/azure';

export default {
    props: {
        programSchema: {
            type: Object,
            required: true,
        },
        nome_tabela: {
            type: String,
            required: true,
        },
        selectedRowIds: {
            type: Array,
            required: true,
        },
        updateSelectedRowIds: {
            type: Function,
            required: true,
        },
        primaryKeyName: {
            type: String,
            required: true,
        },
        parentPrimaryKeyName: {
            type: String,
            required: true,
        },
        parentTableName: {
            type: String,
            required: true,
        },
        parent_id_tabela: {
            type: String,
            required: true,
        },
        table: {
            type: Object,
            required: true,
        },
        setFiles: {
            type: Function,
            required: true,
        },
        setShowFileModal: {
            type: Function,
            required: true,
        },
        id_tabela: {
            type: String,
            required: true,
        }
    },
    setup(props) {
        const store = useStore();
        const browser_route = useRoute();
        const visulizarRespostasConfig = reactive({
            perguntas: [],
            respostas: []
        })

        const showConsultaDadosModal = ref(false)
        const showConfigurarConsultaModal = ref(false)
        const configurarConsultaTargetIndex = ref(null)
        const configuracaoAtual = ref(null)
        const resetMainAndDetailTableForms = inject('resetMainAndDetailTableForms', null)
        const id_empresa_inject = inject('id_empresa', null)
        const perguntas_json_inject = inject('perguntas_json', null)
        const respostas_inject = inject('respostas', null)
        const documents_inject = inject('documents', null)
        const showUploadFileModal = ref(false)
        const loadingFileUpload = ref(false)
        const files = ref([])
        const updateConfiguracaoConsulta = (novaConfig) => {
            props.table.forms[configurarConsultaTargetIndex.value].
                setMutatedValues('configuracao_consulta', JSON.stringify(novaConfig))
            showConfigurarConsultaModal.value = false
        }
        const callFunction = async (p_function, idsObjectArray = []) => {
            const rowIds = props.selectedRowIds
            const alreadyHasIdsObjects = idsObjectArray.length > 0
            
            if (!p_function || !p_function.funcionalidade_habilitada) return;
            if(!alreadyHasIdsObjects) {
                if (rowIds.length == 0 && p_function.exige_registro) {
                    Swal.fire({
                        icon: 'warning',
                        title: 'Atenção',
                        text: 'É necessário selecionar um registro para executar a ação "' + p_function.denominacao + '".',
                        confirmButtonText: 'Ok',
                    })
                    return;
                }
                if (rowIds.length > 1 && !p_function.permite_operacoes_lote) {
                    Swal.fire({
                        icon: 'warning',
                        title: 'Atenção',
                        text: 'A ação "' + p_function.denominacao + '" não permite operação em lotes.',
                        confirmButtonText: 'Ok',
                    })
                    return;
                }

            }
            if([
                '93049f4d-ea1c-49b5-aac2-ccf973e45629', // Replicar Configuração - categoria/departamento - conta financeira
                'dc928673-e5e6-481d-8e1b-eac550b57bdf', // Incluir Itens cotação
                '3fd198d6-43e4-4a13-8def-873718d2138c', // Setar Fornecedor
            ].includes(p_function.id_funcionalidade)) {
                return dynamicList(p_function, props.programSchema.id_tabela)
            }
           


            //excluir documentos || excluir itens
            if(['07fdec1e-448a-4d9a-90ea-4fc6f3e16b6d', 'd965c114-bc5b-4d9b-8994-4904afb6425b'].includes(p_function.id_funcionalidade)) {
                const { value: accept } = await Swal.fire({
                    title: 'Excluir Documentos',
                    text: `Tem certeza que deseja excluir ${rowIds.length} registro(s) selecionado(s)?`,
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#34c38f',
                    cancelButtonColor: '#f46a6a',
                    confirmButtonText: 'Sim',
                    cancelButtonText: 'Não',
                })
                if (!accept) return
            }

            const route = p_function.rota_backend;
            const { schema: { nome_tabela }, forms } = props.table

            if (props.id_tabela == '3172d174-b55e-4993-b863-48d5e92f6663' && p_function.denominacao == 'Configurar Consulta' ) { //sis_consulta_painel
                if(rowIds.length ==0) {
                    Swal.fire({
                    title: 'Selecione um gráfico',
                    text: `É necessário selecionar um (1) gráfico para configurar`,
                    icon: 'warning',
                    showCancelButton: false,
                    confirmButtonColor: '#34c38f',
                    cancelButtonColor: '#f46a6a',
                    confirmButtonText: 'Ok',
                })
                return
                }
                showConfigurarConsultaModal.value = true
                configurarConsultaTargetIndex.value = rowIds[0]
                configuracaoAtual.value = JSON.parse(forms[configurarConsultaTargetIndex.value].getValue('configuracao_consulta'))
                return
            }

            const envio = {
                id_programa: browser_route.params.targetId,
                condicao_adicional: "",
                lista_id_empresa: JSON.parse(localStorage.getItem('companiesGlobalFilter')) ?? [],
                data_trabalho: JSON.parse(localStorage.getItem('dataTrabalho')),
                navegador: false,
                ordenacao: null,
                quantidade_registros: 100,
                pagina: 0,
                rota: props.programSchema.rota_backend + route,
                id: browser_route.query.registro_id,
                dados: {
                    [nome_tabela]: {
                        registros: alreadyHasIdsObjects ? idsObjectArray : 
                        rowIds.map((index) => {
                            const id = forms[index].mutatedValues[props.primaryKeyName]

                            if (['3c4a4151-2aa0-46d4-886c-d06c24f1ee5b', 'a70db5f2-2187-cacf-3545-f3b8a9000888', '2ae81b73-e0fb-496a-8ea9-633c24e82f35'].includes(props.id_tabela)) { //sis_documento
                                const { mutatedValues:
                                    {
                                        id_titulo_financeiro,
                                        link_documento,
                                        nome_documento,
                                        id_empresa
                                    }
                                } = forms.find(form => form.mutatedValues.id_documento == id)
                                return {
                                    id_documento: parseInt(id),
                                    id_titulo_financeiro,
                                    link_documento,
                                    nome_documento,
                                    id_empresa
                                }
                            } else if (props.id_tabela == '677381ef-3233-be8a-eaab-227f0396a396') { //evento_titulo_financeiro
                                return { id_evento: parseInt(id) }
                            } else if (props.id_tabela == '3172d174-b55e-4993-b863-48d5e92f6663' && p_function.id_funcionalidade == 'aa6e3c22-575c-411b-9217-b9265be3e713') {
                                // sis_consulta_painel -> Obter Dados Consulta SQL
                                return forms[index].mutatedValues.id_consulta ? { id_consulta: forms[index].mutatedValues.id_consulta } : false
                             } else if (p_function.id_funcionalidade == '40cec6b1-9fce-47d5-b0e2-eadd3be50c63') { //reprovar item
                                return { 
                                    [props.primaryKeyName]: parseInt(id),
                                    observacao_cancelamento: forms.find(form => form.mutatedValues[props.primaryKeyName] == id).mutatedValues.observacao_cancelamento,
                                    [props.parentPrimaryKeyName]: browser_route.query.registro_id
                                }
                            } else {
                                return { 
                                    [props.primaryKeyName]: parseInt(id),
                                    [props.parentPrimaryKeyName]: browser_route.query.registro_id
                                }
                            }
                        })
                    },
                },
            }

            if(browser_route.query.registro_id){
                envio.dados[props.parentTableName] = {
                    registros: [
                        {
                            [props.parentPrimaryKeyName]: browser_route.query.registro_id
                        },
                    ],
                }
            }

            if (p_function.id_funcionalidade == 'aa6e3c22-575c-411b-9217-b9265be3e713') { // Obter Dados Consulta SQL
                if (envio.dados[nome_tabela].registros.includes(false)) {
                    Swal.fire({
                        icon: 'warning',
                        title: 'Atenção',
                        text: 'É necessário preencher o campo "Consulta" para executar a ação "' + p_function.denominacao + '".',
                        confirmButtonText: 'Ok',
                    })
                    return;
                }
            }

            return await sendFunctionRequest(envio)
            .then((res) => {
                const data = res.data
                if (!data.status) {
                    Swal.fire({
                        icon: "error",
                        text: data.mensagem ? data.mensagem : data.erro,
                        confirmButtonColor: "#34c38f",
                        confirmButtonText: "OK",
                    });
                } else {
                    if (p_function.id_funcionalidade == 'b9d6d7c6-34e6-44b2-a846-147306fe1c23') { //download documentos
                        downloadDocument('Documentos.zip', data.retorno)
                    } else if (p_function.id_funcionalidade == '07fdec1e-448a-4d9a-90ea-4fc6f3e16b6d') { //excluir documentos
                        resetMainAndDetailTableForms(data.retorno.dados)
                        props.updateSelectedRowIds([])
                        Swal.fire({
                            icon: "success",
                            text: "Excluído com sucesso!",
                            confirmButtonColor: "#34c38f",
                            confirmButtonText: "OK",
                        });
                    } else if (p_function.id_funcionalidade == 'c74f780a-8876-42f5-87ec-c28d98f2ae3f') { //aprovar item
                        resetMainAndDetailTableForms(data.retorno.dados)
                        props.updateSelectedRowIds([])
                        Swal.fire({
                            icon: "success",
                            text: "Aprovado com sucesso!",
                            confirmButtonColor: "#34c38f",
                            confirmButtonText: "OK",
                        });
                    } else if (p_function.id_funcionalidade == '40cec6b1-9fce-47d5-b0e2-eadd3be50c63') { //reprovar item
                        resetMainAndDetailTableForms(data.retorno.dados)
                        props.updateSelectedRowIds([])
                        Swal.fire({
                            icon: "success",
                            text: "Reprovado com sucesso!",
                            confirmButtonColor: "#34c38f",
                            confirmButtonText: "OK",
                        });
                    } else if(p_function.id_funcionalidade == 'dc928673-e5e6-481d-8e1b-eac550b57bdf') { //incluir itens
                        resetMainAndDetailTableForms(data.retorno.dados)
                        resetAddItemConfigObject()
                        props.updateSelectedRowIds([])
                        Swal.fire({
                            icon: 'success',
                            title: 'Sucesso!',
                            text: 'Incluído com sucesso!',
                        })
                    } else if(p_function.id_funcionalidade == 'd965c114-bc5b-4d9b-8994-4904afb6425b') { //excluir itens
                        resetMainAndDetailTableForms(data.retorno.dados)
                        props.updateSelectedRowIds([])
                        Swal.fire({
                            icon: "success",
                            text: "Excluído com sucesso!",
                            confirmButtonColor: "#34c38f",
                            confirmButtonText: "OK",
                        });
                    } else if (p_function.id_funcionalidade == '3e95322a-8f41-4c21-8bfa-9c9908cdbeac') { //visualizar documentos
                        props.setFiles(data.retorno.dados.sis_documento.registros), props.setShowFileModal(true)
                    } else if (p_function.id_funcionalidade == 'aa6e3c22-575c-411b-9217-b9265be3e713') { //obter dados consulta sql
                        const dados = data.retorno
                        if (dados.length == 0) {
                            Swal.fire({
                                icon: "info",
                                text: "Nenhum dado encontrado.",
                                confirmButtonColor: "#34c38f",
                                confirmButtonText: "OK",
                            });
                            return
                        }
                        const columns = Object.keys(dados[0])
                        showConsultaDadosModal.value = true
                        document.getElementById('grid_consulta_wrapper').innerHTML = ''
                        const grid = new Grid()
                        grid.updateConfig({
                            columns: columns.map(col => { return { name: col, minWidth: '110px' } }),
                            data: [],
                            pagination: { enabled: true, limit: 10 },
                            search: true,
                            language: ptBR,
                            className: {
                                footer: 'pt-0',
                                pagination: 'text-end',
                                paginationSummary: 'mb-1 fs-12',
                                td: 'px-1 py-0',
                                th: 'position-relative',
                                paginationButton: 'px-2 py-1',
                                table: 'position-relative',
                            },
                            style: {
                                container: {
                                    display: 'flex', flexDirection: 'column',
                                },
                            }
                        }).render(document.getElementById('grid_consulta_wrapper'))
                        grid.updateConfig({
                            data: dados.map((row) => columns.map(col => row[col]))
                        }).forceRender()

                        grid.on('ready', () => {
                            const targetTable = ref(document.querySelector('#grid_consulta_wrapper .gridjs-wrapper .gridjs-table'));
                            const observableTargetTable = document.querySelector('#grid_consulta_wrapper .gridjs-wrapper .gridjs-table');
                            const resizeObserver = new ResizeObserver((entries) => {
                                for (const entry of entries) {
                                    if (entry.contentRect && entry.contentRect.width > 0) {
                                        makeResizable(targetTable)
                                        resizeObserver.unobserve(observableTargetTable);
                                    }
                                }
                            })
                            resizeObserver.observe(observableTargetTable);
                        })
                    }
                      else if(p_function.id_funcionalidade == 'a2ec1dcd-7c12-4d06-86f0-f6776bb558a1') { //visualizar dados pbi
                        store.commit('painelPrograma/setPainels',data.retorno.dados.vw_painel.registros)
                         store.commit('painelPrograma/setRefreshPBI')
                    } else {
                        resetMainAndDetailTableForms(data.retorno.dados)
                        props.updateSelectedRowIds([])
                        Swal.fire({
                            icon: "success",
                            text: "Operação realizada com sucesso!",
                            confirmButtonColor: "#34c38f",
                            confirmButtonText: "OK",
                        });
                      }
                }
                return res.data
            })
            .catch((e)=> {
                return e
            })
        }

        const valueListTitles = ref([])
        const valueListGrid = ref(null);
        var DYNAMIC_VALUE_LIST_TABLE_FUNCTIONS = ref(null);
        
        const item_sis_lista_edicao = ref(null)
        


        
        
        const filteredListOptions = ref([])
        const addItemConfigObject = reactive({
            saveLoading: false,
            show: false,
            searchString: '',
            filteredListOptions: [],
            modalListFilterMinDigitShow: false,
            inputLoading: false,
            selectedOption: null,
        })
        const valueListTableId = uuidv4()
        const resetAddItemConfigObject = () => {
            addItemConfigObject.saveLoading = false
            addItemConfigObject.show = false
            addItemConfigObject.searchString = ''
            addItemConfigObject.filteredListOptions = []
            addItemConfigObject.modalListFilterMinDigitShow = false
            addItemConfigObject.inputLoading = false
            addItemConfigObject.selectedOption = null
            document.getElementById(valueListTableId).innerHTML = "";
        }
        const showAddItemsModal = () => {
            resetAddItemConfigObject()
            addItemConfigObject.show = true
        }
        const createTable = (registros) => {
            const columns = valueListTitles.value.map((title, index) => {
                return {
                    titulo: title,
                    nome: 'codigo'+[index+1]
                }
            })
            columns.push({
                titulo: 'Denominação',
                nome: 'titulo'
            })

            document.getElementById(valueListTableId).innerHTML = "";
            DYNAMIC_VALUE_LIST_TABLE_FUNCTIONS.value = useValueListTable(
                valueListGrid,
                valueListTableId,
                columns
            );
            DYNAMIC_VALUE_LIST_TABLE_FUNCTIONS.value.setTableColumns([]);
            DYNAMIC_VALUE_LIST_TABLE_FUNCTIONS.value.updateConfig(registros);
        }
        const handleListOptionsFilter = () => {
            if(addItemConfigObject.searchString.length < 3){
                addItemConfigObject.modalListFilterMinDigitShow = true;
                return false;
            }
            addItemConfigObject.modalListFilterMinDigitShow = false;

            addItemConfigObject.inputLoading = true;            
            getValueListById("da9fd874-252f-4195-b343-6280f468bdcb", addItemConfigObject.searchString)
                .then(res => {
                    if(!res) {
                        addItemConfigObject.inputLoading = false;
                        return false;
                    }
                    filteredListOptions.value = res.sis_lista_valor.sis_opcao_lista_valor;
                    createTable(res.sis_lista_valor.sis_opcao_lista_valor ?? [])
                    addItemConfigObject.inputLoading = false;
                })
                .catch((error) => {
                    addItemConfigObject.inputLoading = false;
                    console.log(error)
                })
        }
        const saveSelectedItems = async () => {
            if(!DYNAMIC_VALUE_LIST_TABLE_FUNCTIONS.value || DYNAMIC_VALUE_LIST_TABLE_FUNCTIONS.value.currentSelectedRows.length == 0){
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Nenhum item selecionado!',
                })
                return false
            }
            const selectedValues = (DYNAMIC_VALUE_LIST_TABLE_FUNCTIONS.value.currentSelectedRows)
            const item_ids = selectedValues.map(id => {
                const rowRegisterData = DYNAMIC_VALUE_LIST_TABLE_FUNCTIONS.value.getRowRegister(id)
                return {
                    id_item: rowRegisterData.id,
                    id_cotacao: browser_route.query.registro_id,
                    quantidade: 1,
                }
                
            })
            // incluir itens
            addItemConfigObject.saveLoading = true
            const res = await callFunction(props.programSchema.sis_funcionalidade.find(f => f.id_funcionalidade == 'dc928673-e5e6-481d-8e1b-eac550b57bdf'), item_ids)
            if(!res.status){
                addItemConfigObject.saveLoading = false
            }
        }
       
        const saveFiles = async () => {
            const documentsToUpload = files.value.map(file => {
                return {
                    nome_documento: file.name,
                    arquivo_base64: null,
                    tamanho: file.size,
                    descricao: file.descricao,
                    id_empresa: id_empresa_inject
                }
            });

            loadingFileUpload.value = true;
            const sfunction = props.programSchema.sis_funcionalidade.find(f => f.id_funcionalidade == 'dd34d8e4-d993-4e6b-8c99-a411a959550a')
            const body = {
                id: browser_route.query.registro_id,
                id_programa: browser_route.params.targetId,
                condicao_adicional: "",
                lista_id_empresa: JSON.parse(localStorage.getItem('companiesGlobalFilter')) ?? [],
                data_trabalho: JSON.parse(localStorage.getItem('dataTrabalho')),
                navegador: false,
                ordenacao: null,
                quantidade_registros: 100,
                pagina: 0,
                dados: {
                        sis_documento: {
                            registros: [...documentsToUpload],
                    },
                    [props.parentTableName]: {
                        registros: [
                            {
                                [props.parentPrimaryKeyName]: browser_route.query.registro_id
                            },
                        ],
                    },
                },
                rota: props.programSchema.rota_backend + sfunction.rota_backend,
            }

            sendFunctionRequest(body)
            .then(async (res) => {
                if (!res.data?.status) {
                    Swal.fire({
                        icon: "error",
                        text: res.data.mensagem ? res.data.mensagem : res.data.erro,
                        confirmButtonColor: "#34c38f",
                        confirmButtonText: "OK",
                    });
                    loadingFileUpload.value = false;
                } else {
                    resetMainAndDetailTableForms(res.data.retorno.dados)
                    showUploadFileModal.value = false;
                    loadingFileUpload.value = false;
                    
                    const documents = res.data.retorno.dados.sis_documento.registros
                    const documentsToUploadAzure = []
                    files.value.forEach(file => {
                        const filteredDocuments = documents
                            .filter(doc => doc.nome_documento == file.name && doc.tamanho == file.size)
                            .sort((a, b) => new Date(b.data_inclusao) - new Date(a.data_inclusao))
                        const document = filteredDocuments[0] ?? null
                        if (document) {
                            document.file = file
                            documentsToUploadAzure.push(document)
                        }
                    })

                    const azurehelper = new AzureHelper()
                    await azurehelper.uploadDocuments(documentsToUploadAzure)
                }
            });
        }

        async function dynamicList(funcionalidade, id_tabela = null){
            const { schema: { nome_tabela }, forms } = props.table
            const rowIds = props.selectedRowIds
            const asyncJob = {
                id: uuidv4(),
                title: null,
                message: null,
                status: 'running'
            }
            const primaryKeyName = props.programSchema.sis_tabela.sis_campo_tabela.find((column) => column.chave_primaria).nome
            const body = {
                id : browser_route.query.registro_id,
                solicita_tfa: funcionalidade.solicita_tfa,
                id_programa: props.programSchema.id_programa,
                condicao_adicional: "",
                lista_id_empresa: JSON.parse(localStorage.getItem('companiesGlobalFilter')) ?? [],
                data_trabalho: JSON.parse(localStorage.getItem('dataTrabalho')),
                navegador: false,
                ordenacao: null,
                quantidade_registros: 0,
                pagina: 0,
                rota: props.programSchema.rota_backend + funcionalidade.rota_backend,
                dados: {
                    [props.programSchema.sis_tabela.nome_tabela]: {
                        registros: [{
                            [primaryKeyName]: browser_route.query.registro_id
                        }]
                    },
                    [nome_tabela]: {
                        registros: rowIds.map((index) => {
                            const id = forms[index].mutatedValues[props.primaryKeyName]

                            if (funcionalidade.id_funcionalidade == '40cec6b1-9fce-47d5-b0e2-eadd3be50c63') { //reprovar itens
                                return { 
                                    [props.primaryKeyName]: parseInt(id),
                                    observacao_cancelamento: forms.find(form => form.mutatedValues[props.primaryKeyName] == id).mutatedValues.observacao_cancelamento,
                                    [props.parentPrimaryKeyName]: browser_route.query.registro_id
                                }
                            } else {
                                return { 
                                    [props.primaryKeyName]: parseInt(id),
                                    [props.parentPrimaryKeyName]: browser_route.query.registro_id
                                }
                            }
                        })
                    },
                }
            }

            const listValueIdOrNull = getListValueIdByFunctionalityId({ id_funcionalidade: funcionalidade.id_funcionalidade, id_tabela: id_tabela })
            const res = await getValueListById(listValueIdOrNull.id_lista_valor)

            store.commit('massActionsModals/setProperty', [
                {
                    key: 'showDynamicFilterValueListModal',
                    value: true
                },
                {
                    key: 'sis_lista_valor',
                    value: res.sis_lista_valor
                },
                {
                    key: 'onSave',
                    value: (ids_object) => {
                        return sendFunctionRequest(
                            { ...body, dados: { ...body.dados, ...ids_object }},
                            (res) => {
                                asyncJob.message = 'Operação concluída!'
                                asyncJob.status = 'done'
                                store.commit('asyncJobs/updateJob', { ...asyncJob })
                                resetMainAndDetailTableForms(res.data.retorno.dados)
                                props.updateSelectedRowIds([])
                            },
                            (error) => {
                                asyncJob.message = error
                                asyncJob.status = 'failed'
                                store.commit('asyncJobs/updateJob', { ...asyncJob })
                            },
                            true,
                            () => {
                                asyncJob.title = props.programSchema.denominacao + ' > ' + funcionalidade.denominacao
                                asyncJob.message = 'Operação em andamento...'
                                asyncJob.status = 'running'
                                store.commit('asyncJobs/addJob', asyncJob)
                            }
                        )
                    }
                }
            ])
        }

        const showGlobalUploadFileModal = () => {
            const sfunction = props.programSchema.sis_funcionalidade.find(f => f.id_funcionalidade == 'dd34d8e4-d993-4e6b-8c99-a411a959550a')
            store.commit('massActionsModals/setProperty', [
                {
                    key: 'globalUploadFileModalShow',
                    value: true
                },
                {
                    key: 'globalUploadFileModalTextButton',
                    value: 'Salvar'
                },
                {
                    key: 'globalUploadFileModalProperties',
                    value: {
                        id_empresa: id_empresa_inject,
                        id_programa: browser_route.params.targetId,
                        id_registro: browser_route.query.registro_id,
                        nome_tabela: props.parentTableName,
                        nome_chave_primaria: props.parentPrimaryKeyName,
                        rota: props.programSchema.rota_backend + sfunction.rota_backend,
                    }
                },
                {
                    key: 'globalUploadFileModalOnSave',
                    value: (res) => {
                        if (!res.data?.status) {
                            return Swal.fire({
                                icon: "error",
                                text: res.data.mensagem ? res.data.mensagem : res.data.erro,
                                confirmButtonColor: "#34c38f",
                                confirmButtonText: "OK",
                            });
                        }
                        resetMainAndDetailTableForms(res.data.retorno.dados)
                    }
                },
            ])
        }

        return {
            valueListTableId,
            item_sis_lista_edicao,
            saveSelectedItems,
            addItemConfigObject,
            handleListOptionsFilter,
            saveFiles,
            files,
            showUploadFileModal,
            loadingFileUpload,
            showAddItemsModal,
            configuracaoAtual,
            callFunction,
            showConsultaDadosModal,
            showConfigurarConsultaModal,
            updateConfiguracaoConsulta,
            configurarConsultaTargetIndex,
            visulizarRespostasConfig,
            perguntas_json_inject,
            respostas_inject,
            documents_inject,
            showGlobalUploadFileModal
        }
    },
    components: {
        ConfiguracaoConsulta,
        uploadFile,
        FunctionButtons,
        VisualizarRespostasCollapse
    },
    directives: {
        debounce: vue3Debounce({ lock: true })
    },

}
</script>
<template>
    <FunctionButtons
        :functions="programSchema.sis_funcionalidade" 
        :parent_id_tabela="parent_id_tabela"
        :id_tabela="id_tabela"
        :callFunction="callFunction"
        :showUploadFileModal="() => showGlobalUploadFileModal()"
        :showAddItemsModal="showAddItemsModal"
    />

    <VisualizarRespostasCollapse v-if="perguntas_json_inject && table.forms.length > 0" 
        :perguntas="perguntas_json_inject" 
        :respostas="respostas_inject"
        :formRespostas="table.forms"
        :documents="documents_inject"
        :programSchema="programSchema"
        :setShowFileModal="setShowFileModal"
        :setFiles="(files) => setFiles(files)"
    />

    <!-- Consulta Dados Modal -->
    <b-modal id="consultaDadosModal" fade scrollable centered size="xl" hide-header hide-footer
        v-model="showConsultaDadosModal">
        <div class="card-body">
            <div class="row">
                <div class="col-12">
                    <div id="grid_consulta_wrapper"></div>
                </div>
            </div>
        </div>
    </b-modal>
    <!-- Configurar Consulta Modal -->
    <ConfiguracaoConsulta 
        :id_tabela="this.$props.id_tabela" 
        :visible="showConfigurarConsultaModal" 
        :UpdateShowModal="(v) => showConfigurarConsultaModal = v"
        :configuracaoAtual="configuracaoAtual" @updateConfiguracao="updateConfiguracaoConsulta" 
        :painel_consulta="this.$props.table.forms[configurarConsultaTargetIndex]?.mutatedValues ?? null"
    />
    <!-- Upload de documentos Modal -->
    <b-modal id="documentUploadModal" title="Adicione Arquivos" fade scrollable centered size="lg" hide-footer
        v-model="showUploadFileModal">
        <uploadFile :updateFile="(data) => (files = data)" />
        <div class="d-grid gap-2">
            <div v-if="loadingFileUpload" class="text-center">
                <b-spinner variant="success" />
            </div>
            <b-button v-else class="btn btn-sm btn-success" @click="saveFiles()">
                Salvar
            </b-button>
        </div>
    </b-modal>

    <!-- Incluir Itens Modal -->
    <b-modal size="lg" v-model="addItemConfigObject.show" centered scrollable :title="item_sis_lista_edicao?.titulo">
        <div>
            <div class="row mb-4">
                <div class="col-12">
                    <form @submit.prevent>
                        <div class="input-group">
                            <input type="text" v-model="addItemConfigObject.searchString" v-debounce:300ms.lock.cancelonempty="() => handleListOptionsFilter(addItemConfigObject.searchString)" required minlength="3" class="form-control" placeholder="Buscar...">
                            <button v-if="addItemConfigObject.inputLoading" class="btn">
                                <b-spinner variant="primary" small></b-spinner>
                            </button>
                        </div>
                        <small v-if="addItemConfigObject.modalListFilterMinDigitShow" class="text-danger">A busca deve conter ao menos 3 caracteres</small>
                    </form>
                </div>
            </div>
            <div :id="valueListTableId"></div>
        </div>
        <template #footer>
            <div class="row w-100">
                <button v-if="addItemConfigObject.saveLoading" type="button"
                    class="mt-2 ms-auto btn btn-primary shadow-sm col-12 col-md-3 btn-load"
                    style="height: 40px; min-width: 100px;">
                    <span class="d-flex align-items-center justify-content-center gap-2">
                        <span class="spinner-border flex-shrink-0" role="status"></span> Carregando
                    </span>
                </button>
                <button type="button" v-else
                    class="mt-2 ms-auto btn btn-primary shadow-sm col-12 col-md-3"
                    style="height: 40px; min-width: 100px;"
                    @click="saveSelectedItems"
                ><i class="ri-save-2-fill align-bottom me-1"></i> Salvar</button>
            </div>
        </template>
    </b-modal>
</template>