<script>
import { useStore } from 'vuex';
import useProgram from '@/composables/program';
import { onMounted, watch } from 'vue';
import FormBotoes from '@/components/forms_bottom_buttons/index.vue'
import InsightResponseJsonGeneratedForm from '@/components/tabela_detalhe/insightResponseForm.vue';
import { ref } from 'vue';
import BrForm from '@/helpers/forms';
import CustomInput from '@/components/custom_input/index.vue';
import Swal from 'sweetalert2';
import router from '@/router';
import { ApiService } from '@/helpers/api.service';
import { sendFunctionRequest } from '@/helpers/dynamicTable/sendFunctionRequest';
import AzureHelper from '../../../../helpers/azure';

export default {
    props: {
        program_schema: {
            type: Object,
            required: true,
        },
        dados: {
            type: Object,
            required: true,
        },
        registro_id: {
            type: String,
            required: false,
        },
        inModal: {
            type: Boolean,
            required: false,
            default: false,
        },
        additionalData: {
            type: Object,
            required: false,
            default: null,
        },
        third_party_body: {
            type: Object,
            required: false,
            default: null,
        },
        onSuccess: {
            type: Function,
            required: false,
            default: null,
        }
    },
    setup(props) {
        const store = useStore();
        const id_empresa_HOLDING_ADMINISTRADORA_DE_BENS = 1423154023;
        const formType = store.state.programForm.formType;
        const programa = {
            program_schema: props.program_schema,
            dados: props.dados,
            registro_id: props.registro_id
        };
        const reRender = ref(false)
        const btnloading = ref(false)
        const {
            setProgramForm,
            addNewDetailTableForm,
            removeDetailTableForm,
            handleFormSubmit,
            sis_detail_table,
            baseFormModifier,
            loading,
        } = useProgram(formType, programa);
        const formRef = ref(null);
        const formModifierRespostaFormulario = ref(null);
        const perguntas = ref([])
        const empresa_id = ref(null)
        const incluirDocumentosFunction = ref(null)
        const visualizarDocumentosFunction = ref(null)
        const excluirDocumentosFunction = ref(null)
        const loadingFileUpload = ref(false)
        incluirDocumentosFunction.value = programa.program_schema.sis_funcionalidade.find(f => f.id_funcionalidade == 'dd34d8e4-d993-4e6b-8c99-a411a959550a')
        visualizarDocumentosFunction.value = programa.program_schema.sis_funcionalidade.find(f => f.id_funcionalidade == '3e95322a-8f41-4c21-8bfa-9c9908cdbeac')
        excluirDocumentosFunction.value = programa.program_schema.sis_funcionalidade.find(f => f.id_funcionalidade == '07fdec1e-448a-4d9a-90ea-4fc6f3e16b6d')
        const tableName = programa.program_schema.sis_tabela.nome_tabela;
        const filesToBeSaved = ref([])
        const closeNow = ref(false)
        const loadingShowLinks = ref(false)

        onMounted(() => {
            setProgramForm();
        });

        watch(() => baseFormModifier.value, () => {
            if(props.program_schema.sis_tabela.id_tabela == '4d1388f0-4c14-45da-b4eb-b753fdf35f1f'){
                baseFormModifier.value.setInitialValues('empresa_id', props.dados['sis_resposta_formulario'].registros[0].id_empresa)
                baseFormModifier.value.setMutatedValues('empresa_id', props.dados['sis_resposta_formulario'].registros[0].id_empresa)
            }
        })
        perguntas.value = [...props.dados[tableName].registros[0].perguntas_json].sort((a, b) => a.sis_pergunta.ordem_exibicao - b.sis_pergunta.ordem_exibicao)
        
        
        perguntas.value.forEach(p => {
            filesToBeSaved.value.push({
                id_pergunta_formulario: p.sis_pergunta.id_pergunta_formulario,
                files: [],
                error: null,
                wasSaved: false
            })
        })

        const default_body = {
            id: props.registro_id,
            id_programa: programa.program_schema.id_programa,
            condicao_adicional: "",
            lista_id_empresa: JSON.parse(localStorage.getItem('companiesGlobalFilter')) ?? [],
            data_trabalho: JSON.parse(localStorage.getItem('dataTrabalho')),
            navegador: false,
            ordenacao: null,
            quantidade_registros: 100,
            pagina: 0,
        }

        const updateFilesToBeSaved = (id_pergunta_formulario, files) => {
            if(programa.program_schema.sis_tabela.id_tabela === '4d1388f0-4c14-45da-b4eb-b753fdf35f1f') {
                saveFilesImediatly(id_pergunta_formulario, files)
            }else{
                const target = filesToBeSaved.value.find(ftbs => ftbs.id_pergunta_formulario === id_pergunta_formulario)
                if (target) {
                    target.files.push(...files)
                }
            }
        }

        const mapFromCategories = new Map(
            perguntas.value.map(p => {
                return {
                    id: p.sis_pergunta.id_categoria_pergunta, 
                    ordem_exibicao: p.sis_pergunta.ordem_exibicao_categoria,
                    titulo: p.sis_pergunta.titulo_categoria
                }
            }).map(c => [c.id, c])
        )
        let groupNames = [...mapFromCategories.values()]
        groupNames = groupNames.sort((a, b) => a.ordem_exibicao - b.ordem_exibicao)
        groupNames.push(groupNames.pop())
        
        const perguntasJsonTableSchema = {
                sis_tabela:{
                    nome_tabela: 'respostas_json',
                    denominacao: 'Respostas',
                    sis_campo_tabela: perguntas.value.map(p => {
                        return {
                            id: p.sis_pergunta.id_pergunta_formulario,
                            nome: p.sis_pergunta.titulo,
                            titulo: p.sis_pergunta.titulo,
                            formato: null,
                            requerido: p.sis_pergunta.requerido,
                            tipo_campo: p.sis_pergunta.tipo_pergunta == 20 ? 1 : p.sis_pergunta.tipo_pergunta,
                            customizado: false,
                            valor_maximo: 999999999,
                            valor_minimo: 0,
                            valor_padrao: p.sis_pergunta.tipo_pergunta == 2 ?  'false' : null,
                            bloquear_edicao: false,
                            id_campo_tabela: p.sis_pergunta.id_pergunta_formulario,
                            opcao_selecao: p.sis_opcao_lista,
                            id_lista_edicao: null,
                            permite_exclusao: false,
                            permite_inclusao: true,
                            id_tabela_detalhe: null,
                            permite_alteracao: true,
                            bloquear_visualizacao: false,
                            id_lista_visualizacao: null,
                        }
                    })
                }
            }
        formModifierRespostaFormulario.value = new BrForm(perguntasJsonTableSchema.sis_tabela);
        
        if(programa.program_schema.sis_tabela.id_tabela === '4d1388f0-4c14-45da-b4eb-b753fdf35f1f') { //sis_usuario_respostas_formulario
            const object = {}
            props.dados.sis_resposta_formulario.registros.forEach( r => {
                object[r.id_pergunta_formulario] = r.resposta
            })
            formModifierRespostaFormulario.value.dataAlreadyExists = true
            formModifierRespostaFormulario.value.setInitialValuesFromObject(object, 'id_pergunta_formulario')
            if(props.dados.sis_documento.registros.length > 0){
                setFilesShowLinks(props.dados.sis_documento.registros)
            }
        }

        watch(() => baseFormModifier.value?.mutatedValues?.empresa_id, (newValue) => {
            if (newValue) {
                empresa_id.value = newValue
            }
        })

        const save = async () => {
            btnloading.value = true;
            const respostasObjeto = {
                nome_tabela: 'sis_resposta_formulario',
                data: []
            }

            perguntas.value.forEach((p) => {
                let resposta = formModifierRespostaFormulario.value.mutatedValues[p.sis_pergunta.id_pergunta_formulario]
                resposta = !resposta && !p.sis_pergunta.requerido ? '' : resposta
                respostasObjeto.data.push({
                    id_pergunta_formulario: p.sis_pergunta.id_pergunta_formulario,
                    id_formulario: p.sis_pergunta.id_formulario,
                    id_empresa: empresa_id.value,
                    resposta: resposta
                })
            })

            //eslint-disable-next-line
            const submit = {
                event: { target: formRef.value }, 
                respostasObjeto: [respostasObjeto], 
                route: props.program_schema.sis_tabela.id_tabela == '9456255e-f9bb-4e94-86e0-0dd3929c47b6' ? props.program_schema.rota_backend+'/incluir_resposta' : null,
                shouldReload: props.program_schema.sis_tabela.id_tabela == '9456255e-f9bb-4e94-86e0-0dd3929c47b6' ? true : false
            }
            if(props.additionalData){
                submit.respostasObjeto.push(props.additionalData)
            }
            const res = await handleFormSubmit(submit.event, submit.respostasObjeto, submit.route, false, true, props.third_party_body ? props.third_party_body : null)
            
            if(res){
                if(!res.status){
                    Swal.fire({
                        icon: "error",
                        text: res.mensagem ? res.mensagem : res.erro,
                        confirmButtonColor: "#34c38f",  
                        confirmButtonText: "OK",
                    });

                    btnloading.value = false;
                } else if(res.error){
                    Swal.fire({
                        icon: "error",
                        text: res.error,
                        confirmButtonColor: "#34c38f",
                        confirmButtonText: "OK",
                    });

                    btnloading.value = false;
                }else{
                    const dados = res.retorno.dados;
                    if(submit.route?.split('/')[2] === 'incluir_resposta'){
                        const surf = dados.sis_usuario_respostas_formulario.registros
                        const sorted = surf.sort((a, b) => a > b)
                        const lastSurf = sorted[surf.length -1]

                        const respostas = dados.sis_resposta_formulario.registros
                        .filter(srf => srf.id_usuario_respostas_formulario === lastSurf.id_usuario_respostas_formulario)

                        await saveFiles(respostas)
                       
                        const textErrors = filesToBeSaved.value.filter(ftbs => ftbs.error)
                        .map(ftbs => {
                            const p = perguntas.value.find(p => p.sis_pergunta.id_pergunta_formulario === ftbs.id_pergunta_formulario)
                            return `[${p.sis_pergunta.titulo_categoria} - ${p.sis_pergunta.titulo}]: <span class="text-danger">${ftbs.error}</span><br/>`
                        }).join('')           

                        // console.log('should return')
                        if(['signin-basic', 'signin-with-email-basic'].includes(localStorage.getItem('lastAcessedRouteName'))){
                            if(!props.inModal){
                                // console.log('replace')
                                router.replace({name: 'default'})
                            }
                        }else{
                            if(!props.inModal){
                                // console.log('back')
                                router.back()
                            }
                        }
                        // console.log('should return 2')

                        if(textErrors){
                            Swal.fire({
                                icon: "error",
                                html: 'As respostas foram salvas, porem ocorreu erro ao tentar salvar os documenos das seguintes perguntas:<br/>' + textErrors,
                                confirmButtonColor: "#34c38f",
                                confirmButtonText: "OK",
                            });
                        }
                    } 
                    btnloading.value = false;
                    Swal.fire({
                        icon: "success",
                        text: 'Salvo!',
                        confirmButtonColor: "#34c38f",
                        confirmButtonText: "OK",
                    });
                    if(props.onSuccess){
                        props.onSuccess(dados)
                    }
                }
            }else{
                btnloading.value = false;
            }
        };

        async function saveFiles(respostas){
            for (const r of respostas){
                const ftbs = filesToBeSaved.value.find(ftbs => ftbs.id_pergunta_formulario === r.id_pergunta_formulario)
                if(ftbs.files.length == 0) continue

                const documentsToUpload = ftbs.files.map(file => {
                    return {
                        nome_documento: file.name,
                        arquivo_base64: null,
                        tamanho: file.size,
                        descricao: file.descricao,
                        id_empresa: r.id_empresa ?? id_empresa_HOLDING_ADMINISTRADORA_DE_BENS,
                    }
                });

                const data = await sendSaveFileRequest(documentsToUpload, r.id_resposta_formulario, true)
                if(!data.status){
                    ftbs.error = data.mensagem ? data.mensagem : data.erro
                }
                ftbs.wasSaved = true

                const documents = data.retorno.dados.sis_documento.registros
                const documentsToUploadAzure = []
                ftbs.files.forEach(file => {
                    const filteredDocuments = documents
                        .filter(doc => doc.nome_documento == file.name && doc.tamanho == file.size)
                        .sort((a, b) => new Date(b.data_inclusao) - new Date(a.data_inclusao))
                    const document = filteredDocuments[0] ?? null
                    if (document) {
                        document.file = file
                        documentsToUploadAzure.push(document)
                    }
                })

                const azurehelper = new AzureHelper()
                azurehelper.uploadDocuments(documentsToUploadAzure)
            }
        }

        async function saveFilesImediatly(id_pergunta_formulario, files){
            loadingFileUpload.value = true;

            const documentsToUpload = files.map(file => {
                return {
                    nome_documento: file.name,
                    arquivo_base64: null,
                    tamanho: file.size,
                    descricao: file.descricao,
                    id_empresa: props.dados.sis_resposta_formulario.registros[0].id_empresa ?? id_empresa_HOLDING_ADMINISTRADORA_DE_BENS,
                }
            });
            
            const id_resposta_formulario = props.dados.sis_resposta_formulario.registros.find(srf => srf.id_pergunta_formulario === id_pergunta_formulario).id_resposta_formulario
            const data = await sendSaveFileRequest(documentsToUpload, id_resposta_formulario, true)
            closeNow.value = false
            closeNow.value = true
            loadingFileUpload.value = false
            
            if(!data.status){
                Swal.fire({
                    icon: "error",
                    text: data.mensagem ? data.mensagem : data.erro,
                    confirmButtonColor: "#34c38f",
                    confirmButtonText: "OK",
                })
            }else{
                const documents = data.retorno.dados.sis_documento.registros
                const documentsToUploadAzure = []
                files.forEach(file => {
                    const filteredDocuments = documents
                        .filter(doc => doc.nome_documento == file.name && doc.tamanho == file.size)
                        .sort((a, b) => new Date(b.data_inclusao) - new Date(a.data_inclusao))
                    const document = filteredDocuments[0] ?? null
                    if (document) {
                        document.file = file
                        documentsToUploadAzure.push(document)
                    }
                })

                const azurehelper = new AzureHelper()
                await azurehelper.uploadDocuments(documentsToUploadAzure)

                setFilesShowLinks(data.retorno.dados.sis_documento.registros)
            }
        }

        async function sendSaveFileRequest(upload, id_resposta_formulario, returnObject = false){
            const body = default_body
            body.dados = {
                    sis_documento: {
                    registros: [...upload],
                },
                sis_resposta_formulario: {
                    registros: [{ id_resposta_formulario}],
                },
                [props.program_schema.sis_tabela.nome_tabela]: {
                    registros: [
                        {
                            [props.program_schema.sis_tabela.sis_campo_tabela.find((column) => column.chave_primaria).nome]: props.registro_id
                        },
                    ],
                },
            },
            body.rota = programa.program_schema.rota_backend + incluirDocumentosFunction.value.rota_backend

            return await ApiService({
                method: 'POST',
                url: `/program`,
                data: body,
                headers: {'authorization': JSON.parse(localStorage.getItem('user')).token}
            })
            .then((res) => {
                if(!returnObject){
                    if (!res.data?.status) {
                        return res.data.mensagem ? res.data.mensagem : res.data.erro
                    }
                }
                return res.data
            })
            .catch(error => {
                if(!returnObject){
                    return error.message
                }
               return {erro: error.message}
            })
        }

        async function setFilesShowLinks(documentos){
            loadingShowLinks.value = true
            const body = default_body
            body.dados = {
                    sis_documento: {
                        registros: documentos.map(d => {
                            return {
                                id_documento: d.id_documento,
                                id_empresa: d.id_empresa,
                                link_documento: d.link_documento,
                                nome_documento: d.nome_documento,
                            }
                        }
                    ),
                },
                [props.program_schema.sis_tabela.nome_tabela]: {
                    registros: [
                        {
                            [props.program_schema.sis_tabela.sis_campo_tabela.find((column) => column.chave_primaria).nome]: props.registro_id
                        },
                    ],
                },
            },
            body.rota = programa.program_schema.rota_backend + visualizarDocumentosFunction.value.rota_backend
            
            await sendFunctionRequest(body)
            .then(res => {
                const data = res.data
                if (!data.status) {
                    Swal.fire({
                        icon: "error",
                        text: data.mensagem ? data.mensagem : data.erro,
                        confirmButtonColor: "#34c38f",
                        confirmButtonText: "OK",
                    });
                } else {
                    filesToBeSaved.value.forEach(ftbs => {
                        ftbs.files = []
                    })
                    documentos.forEach(sd => {
                        const file = {...sd, link_azure: data.retorno.dados.sis_documento.registros.find(d => d.id_documento === sd.id_documento).link_azure}
                        filesToBeSaved.value.find(ftbs => ftbs.id_pergunta_formulario === sd.id_pergunta_formulario).files.push(file)
                    })
                }
            })
            .catch((error) => {
                console.log(error)
            })

            loadingShowLinks.value = false
        }

        const deleteDocumento = (id_pergunta_formulario, index) => {
            Swal.fire({
                text: 'Tem cereteza que deseja excluir este documento?',
                icon: "warning",
                confirmButtonText: "OK",
                showCancelButton: true,
                cancelButtonText: "Cancelar",
            }).then(async (result) => {
                if (result.isConfirmed) {
                    const target = filesToBeSaved.value.find(ftbs => ftbs.id_pergunta_formulario === id_pergunta_formulario)
                    const backupTargetFile = target.files[index]
                    target.files.splice(index, 1)

                    const body = default_body
                    body.dados = {
                        sis_documento: {
                            registros: [{
                                id_documento: backupTargetFile.id_documento,
                                id_empresa: backupTargetFile.id_empresa,
                                link_documento: backupTargetFile.link_documento,
                                nome_documento: backupTargetFile.nome_documento,
                            }]
                        },
                        [props.program_schema.sis_tabela.nome_tabela]: {
                            registros: [
                                {
                                    [props.program_schema.sis_tabela.sis_campo_tabela.find((column) => column.chave_primaria).nome]: props.registro_id
                                },
                            ],
                        },
                    },
                    body.rota = programa.program_schema.rota_backend + excluirDocumentosFunction.value.rota_backend
                    
                    await sendFunctionRequest(body)
                    .then(res => {
                        const data = res.data
                        if (!data.status) {
                            Swal.fire({
                                icon: "error",
                                text: data.mensagem ? data.mensagem : data.erro,
                                confirmButtonColor: "#34c38f",
                                confirmButtonText: "OK",
                            });
                            filesToBeSaved.value.find(ftbs => ftbs.id_pergunta_formulario === id_pergunta_formulario).files.splice(index, 0, backupTargetFile)
                        }else{
                            Swal.fire({
                                icon: "success",
                                text: 'Excluído com sucesso!',
                                confirmButtonColor: "#34c38f",
                                confirmButtonText: "OK",
                            });
                        }
                    })
                    .catch((error)=> {
                        console.log(error)
                        filesToBeSaved.value.find(ftbs => ftbs.id_pergunta_formulario === id_pergunta_formulario).files.splice(index, 0, backupTargetFile)
                    })
                }
            })
        }


        return {
            formType,
            loading,
            btnloading,
            baseFormModifier,
            sis_detail_table,
            addNewDetailTableForm,
            removeDetailTableForm,
            formRef,
            save,
            formModifierRespostaFormulario,
            perguntas,
            groupNames,
            reRender, 
            tableName,
            updateFilesToBeSaved,
            filesToBeSaved,
            incluirDocumentosFunction,
            deleteDocumento,
            loadingFileUpload,
            closeNow,
            loadingShowLinks
        };
    },
    components: {
        FormBotoes,
        InsightResponseJsonGeneratedForm,
        CustomInput
    }

}
</script>
<template>
    <div class="row">
        <div class="col-12">
            <div :class="[!inModal ? 'card' : '']">
                <div class="card-body my-2 mb-3">
                    <template v-if="loading">
                        <div class="text-center">
                            <b-spinner variant="primary"></b-spinner>
                        </div>
                    </template>
                    <template v-else>
                        <form @submit.prevent="save" ref="formRef" class="needs-validation"
                            novalidate>
                            
                            <div class="tab-pane active" id="arrow-program" role="tabpanel">
                                <div class="border p-3 mt-2 mb-5 bg-primary">
                                    <div class="">
                                        <h2 class="text-light">{{dados[tableName].registros[0].titulo}}</h2>
                                        <hr>
                                        <h5 class="card-text text-light">{{dados[tableName].registros[0].descricao}}</h5>
                                    </div>
                                </div>
                            </div>

                            <div v-if="this.$props.program_schema.sis_tabela.id_tabela == '4d1388f0-4c14-45da-b4eb-b753fdf35f1f'">
                                <div class="row">
                                    <div class="col-12 col-md-8 d-flex align-items-center gap-1">
                                        <div class="mb-3 flex-1 ms-md-3">
                                            <label class="form-label text-dark">Empresa</label>
                                            <input readonly="" type="text" class="form-control disabled border-dashed" :value="this.$props.dados['sis_resposta_formulario'].registros[0].titulo_empresa">
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div v-else-if="[2, 4].includes(dados[tableName].registros[0].tipo_parametro)" class="row">
                                <div class="col-12 col-md-8 d-flex align-items-center gap-1">
                                    <div class="mb-3 flex-1 ms-md-4">
                                        <CustomInput 
                                            :formState="baseFormModifier" 
                                            :formType="formType"
                                            :columnSchema="baseFormModifier.getColumnByName('empresa_id')"
                                            :showLabel="true"
                                        />
                                    </div>
                                </div>
                            </div>

                            <!-- se id_tabela iguala a de sis_formulario_resposta ou sis_minhas_respostas_formulario -->
                            <InsightResponseJsonGeneratedForm v-if="['9456255e-f9bb-4e94-86e0-0dd3929c47b6', '4d1388f0-4c14-45da-b4eb-b753fdf35f1f'].includes(this.$props.program_schema.sis_tabela.id_tabela)" 
                                :key="reRender"
                                :formType="formType" 
                                :formModifierRespostaFormulario="formModifierRespostaFormulario"
                                :perguntas="perguntas"
                                :groupNames="groupNames"
                                :tableName="tableName"
                                :updateFilesToBeSaved="updateFilesToBeSaved"
                                :supportDocuments="incluirDocumentosFunction && incluirDocumentosFunction.funcionalidade_habilitada"
                                :filesToBeSaved="filesToBeSaved"
                                :deleteDocumento="deleteDocumento"
                                :loadingFileUpload="loadingFileUpload"
                                :autoClose="program_schema.sis_tabela.id_tabela == '9456255e-f9bb-4e94-86e0-0dd3929c47b6'"
                                :closeNow="closeNow"
                                :loadingShowLinks="loadingShowLinks"
                            />
                            
                            <FormBotoes :formType="formType" :loading="btnloading" :inModal="inModal" />
                        </form>
                    </template>
                </div>
            </div>
        </div>
    </div>
</template>